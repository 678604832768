<template>
  <div>
    <div class="columns" style="margin-top: 15px">
      <div class="column">
        <h2 class="title is-3">Изменить оффер: {{ offer.name }}</h2>
      </div>
      <div class="columns">
        <div class="column">
          <b-button
            class="is-pulled-right"
            type="is-primary"
            icon-right="keyboard-return"
            @click="returnToApps()"
          >
            Вернуться
          </b-button>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-3">
        <b-field label="Название">
          <b-input v-model="offer.name" required></b-input>
        </b-field>
        <b-field label="Название в Keitaro">
          <b-input v-model="offer.keitaro_name" disabled></b-input>
        </b-field>
         <div style="padding-bottom: 30px;">
          <b-field label="Описание">
            <b-input v-model="offer.description" maxlength="200" type="textarea"></b-input>
          </b-field>
        </div>
        <b-field label="Показывать пользователям?">
          <b-switch v-model="offer.show"> </b-switch>
        </b-field>

        <b-field label="Максимальный срок займа">
          <b-input v-model="offer.days" required></b-input>
        </b-field>

        <b-field label="Ставка %">
          <b-input v-model="offer.bet" required></b-input>
        </b-field>

        <b-field label="Возраст">
          <b-input v-model="offer.age" required></b-input>
        </b-field>

        <b-field label="Название в Leadgid чекере">
          <b-input v-model="offer.checker"></b-input>
        </b-field>
      </div>
      <div class="column is-3">
        <b-field label="Процент одобрения">
          <b-input v-model="offer.success_rate" required></b-input>
        </b-field>

        <b-field label="Максимальная сумма займа">
          <b-input v-model="offer.sum" required></b-input>
        </b-field>

        <b-field label="Рейтинг">
          <b-input v-model="offer.rate" required></b-input>
        </b-field>

        <b-field label="Валюта">
          <b-input v-model="offer.currency" required></b-input>
        </b-field>
      </div>
      <div class="column is-5">
                <b-field label="URL изображения c Erid">
          <b-input v-model="offer.img_path_with_erid" disabled></b-input>
        </b-field>
        <b-field label="Изображение c Erid">
          <div class="columns">
            <div class="column is-6">
              <b-upload v-model="offer.img_with_erid" accept="image/*" drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <span class="file-name" v-if="offer.img_with_erid">
                                            {{ offer.img_with_erid.name }}
                                        </span>
                    <span v-else>
                                            <p>Загрузить Изображение</p>
                                        </span>
                  </div>
                </section>
              </b-upload>
            </div>
            <div class="column is-6">
              <v-img
                v-if="offer_img"
                v-bind:src="offer_img"
                width="150px"
                alt="Placeholder image"
              >
              </v-img>
            </div>
          </div>
        </b-field>
        <b-field label="URL изображения без Erid">
          <b-input v-model="offer.img_path_without_erid" disabled></b-input>
        </b-field>
        <b-field label="Изображение без Erid">
          <div class="columns">
            <div class="column is-6">
              <b-upload v-model="offer.img_without_erid" accept="image/*" drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <span class="file-name" v-if="offer.img_without_erid">
                                            {{ offer.img_without_erid.name }}
                                        </span>
                    <span v-else>
                                            <p>Загрузить Изображение</p>
                                        </span>
                  </div>
                </section>
              </b-upload>
            </div>
            <div class="column is-6">
              <v-img
                v-if="offer_img"
                v-bind:src="offer_img"
                width="150px"
                alt="Placeholder image"
              >
              </v-img>
            </div>
          </div>
        </b-field>
      </div>
    </div>
     <div class="columns is-10 justify-content-right">

      <div  class="column is-2">
        <b-button
          type="is-success"
          @click="save(true)"
          v-if="offer.default"
        >
          Сохранить и обновить
        </b-button>
      </div>

      <div  class="column is-2">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="check"
          @click="save(false)"
        >
          Сохранить
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { beforeRouteLeave } from 'vue-router';

export default {
  name: "UpdateOffer",
  created() {
    this.$store.dispatch("GET_OFFER_BY_ID", this.offerId).then(() => {
      // запись копии элемента, чтобы отслеживать изменения
      this.copyOffer = JSON.stringify(this.offer);
    });

    // функция перед закрытием страницы, перезагрузкой
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },

  destroyed() {
      // Удаляем обработчик события beforeunload при уничтожении компонента
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  data() {
    return {
      copyOffer: null,
    };
  },

  data() {
    return {
      copyOffer: null,
    };
  },
  computed: {
    offerId() {
      return this.$route.params.id;
    },
    offer: {
      get() {
        return this.$store.getters.CURRENT_OFFER;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_OFFER", value);
      },
    },
    offer_img() {
      if (this.offer.img) {
        return URL.createObjectURL(this.offer.img);
      }
      return this.offer.img_path;
    },
  },
  methods: {
    handleBeforeUnload(event) {
      // Функция будет вызвана перед закрытием страницы или переходом по внешней ссылке
      if (this.copyOffer !== JSON.stringify(this.offer)) {
        event.returnValue = 'Вы уверены, что хотите покинуть эту страницу?';
      }
    },
    save(updateAll) {
      const isRequired = ['name', 'age', 'bet', 'currency', 'days', 'rate', 'success_rate', 'sum'];
      this.offer.updateAll = updateAll;
      let countReq = 0;
      for (const [key, value] of Object.entries(this.offer)) {
        if (key == 'bet' && value == 0) {
          continue;
        }
        if (isRequired.includes(key) && !value) {
          countReq += 1;
        }
      }
      if (countReq === 0) {
        if ((!this.offer.img_with_erid && !this.offer.img_path_with_erid) || (!this.offer.img_without_erid && !this.offer.img_path_without_erid)) {
          this.$toast.error('Добавьте изображения!');
        }
        else {
          this.$store
          .dispatch("UPDATE_OFFER", this.offer)
          .then(() => {
            this.$toast.success("Оффер изменен!");
            if (this.offer.default === true) {
              this.$router.push({ path: "/offers"}).catch(()=>{});
            }
            else {
              this.$router.push({ path: `/application/update/${this.offer.application_id}` }).catch(()=>{});
            }
          })
          .catch((error) => {
            const exception = error.response.data.exception;
            switch (exception) {
              case 'EmptyOfferException':
                this.$toast.warning('Заполните все данные для включения оффера!');
                break;
              default:
                this.$toast.error('Не удается изменить оффер!');
                break;
            }
          });
        }
      }
      else {
        this.$toast.error('Заполните все данные!');
      }
    },
    returnToApps() {
      if (this.offer.default === true) {
        this.$router.push({ path: "/offers"}).catch(()=>{});
      }
      else {
        this.$router.push({ path: `/application/update/${this.offer.application_id}` }).catch(()=>{});
      }
    },
        // перед сменой адреса
    beforeRouteLeave(to, from, next) {
        if (this.copyOffer !== JSON.stringify(this.offer)) {
          // подтверждение
          this.$buefy.dialog.confirm({
            title: 'Изменения оффера',
            message: 'Внесённые изменения не сохранены',
            confirmText: 'Уйти',
            cancelText: 'Отмена',
            type: 'is-info',
            hasIcon: true,
            onConfirm: () => {
              next();
            },
          });

        } else {
          next();
        }
    },
  },
};
</script>

<style>
.b-numberinput input[type="number"] {
  text-align: center;
  width: 100%;
}

.modal{
    align-items: flex-start !important;
    flex-direction: initial !important;
    padding-top: 100px;
}

@media (max-width: 900px) {
  .modal{
    padding-top: 60px;
  }
}

</style>
